import React, { FC } from 'react'

const LeftCarouselIcon: FC = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="20"
      cy="20"
      r="19.5"
      fill="white"
      stroke="#E5E5EA"
    />
    <path
      d="M22.1317 17.1339L19.2666 20L22.1317 22.8661C22.6199 23.3543 22.6199 24.1457 22.1317 24.6339C21.6436 25.122 20.8521 25.122 20.364 24.6339L16.614 20.8839C16.4262 20.6961 16.3107 20.4635 16.2673 20.2205L16.25 20.0738V19.9262C16.2673 19.6313 16.3886 19.3414 16.614 19.1161L20.364 15.3661C20.8521 14.878 21.6436 14.878 22.1317 15.3661C22.6199 15.8543 22.6199 16.6457 22.1317 17.1339Z"
      fill="#8E8E93"
    />
  </svg>
)

export default LeftCarouselIcon

import { FC } from 'react'
import { Button, Panel, Stack } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Link from 'next/link'

import useUrls from '../../../services/useUrls'
import { getSmallPreview, imageLoader } from '../../../utils/images'
import { ProductMedia } from '../../../utils/types/Product'
import useIsOnMobile from '../../../services/useIsOnMobile'
import { ScreenSize, TOTAL_SKUS_VALUE_PROPOSITION } from '../../../utils/constants'
import Avatar from '../../Avatar/Avatar'

import styles from '../../../styles/SeeAllCard.module.less'

interface SeeAllCardProps {
  href: string
  middleImg?: ProductMedia
  categoryOrSupplierName?: string
}

const SeeAllCard: FC<SeeAllCardProps> = (props) => {
  const {
    middleImg,
    href,
    categoryOrSupplierName = 'products',
  } = props

  const isOnMobile = useIsOnMobile(ScreenSize.sm)

  const { urlT } = useUrls()
  const { pathname } = useRouter()

  const staticImages = [
    'gloves',
    'clothing',
    'shipping',
  ].map((text) => `show-more-card-${text}.webp`)

  const categoryOrSupplierImage = getSmallPreview(middleImg)

  if (middleImg) {
    staticImages[1] = categoryOrSupplierImage
  }

  const { t } = useTranslation('landing')

  const buttonText = categoryOrSupplierName !== 'products'
    ? t('products:Browse {{category}}', { category: categoryOrSupplierName })
    : t('common:Browse products')

  const redirectUrl = urlT(href)

  const renderLogo = (img: string) => (
    <div
      className={styles.logo}
      key={img}
    >
      <Avatar
        size="xl"
        imageSrc={img}
        altName="Product logo"
        key={imageLoader(img)}
      />
    </div>
  )

  const target = pathname.startsWith('/cart') ? '_blank' : undefined

  return (
    <Link
      target={target}
      href={redirectUrl}
    >
      <Panel
        bordered
        bodyFill
        className={styles['see-all-card']}
      >
        <Stack
          spacing={30}
          justifyContent="center"
        >
          {isOnMobile
            ? renderLogo(categoryOrSupplierImage)
            : staticImages.map(renderLogo)}
        </Stack>
        <div className="h6 margin-top-spacer-tripple margin-bottom-spacer">
          {t('Find exactly what you need')}
        </div>
        <p>
          {t('Find +{{count}} products from hundreds of brands', { count: TOTAL_SKUS_VALUE_PROPOSITION })}
        </p>
        <Button
          appearance="ghost"
          className={styles['browse-button']}
        >
          {buttonText}
        </Button>
      </Panel>
    </Link>
  )
}

export default SeeAllCard

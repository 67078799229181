import React, { FC } from 'react'
import { Tag } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import LegacyTagIcon from '@rsuite/icons/legacy/Tag'

import { Product } from '../utils/types/Product'
import { formatPriceNumber } from '../utils/math'
import { getPricePerPieceUnit } from '../lib/salesUnit'
import { getProductCurrency } from '../lib/product'
import { Locale } from '../external'
import { getProductDiscounts } from '../lib/resources/discount'

import styles from '../styles/Tag.module.less'

interface BulkDiscountTagProps {
  product: Product
  country: Locale
  isOnProductCard?: boolean
}

const BulkDiscountTag: FC<BulkDiscountTagProps> = (props) => {
  const { product, country, isOnProductCard = false } = props

  const { t } = useTranslation('common')

  const productPricingUnit = getPricePerPieceUnit(product)
  const currency = getProductCurrency(product, country)
  const discountList = getProductDiscounts(product)

  const TagIcon = isOnProductCard ? <LegacyTagIcon /> : null

  const [cheapestDiscount] = discountList.sort(
    (discountA, discountB) => Number(discountA.referencePrice) - Number(discountB.referencePrice),
  )

  const discountTagText = isOnProductCard
    ? ` ${t('common:priceStartingFrom')} ${formatPriceNumber(cheapestDiscount?.referencePrice)} ${currency}`
    : ` ${t('common:priceStartingFrom')} ${formatPriceNumber(cheapestDiscount?.referencePrice)} ${currency} / ${t(productPricingUnit, { count: 1 })}`

  if (!discountList.length) {
    return null
  }

  return (
    <Tag
      as="span"
      size="lg"
      className={`
    ${styles['discount-tag']}
    margin-left-spacer-half 
    ${isOnProductCard ? styles['product-card-discount-tag'] : ''}`}
    >
      {TagIcon}
      {discountTagText}
    </Tag>
  )
}

export default BulkDiscountTag
